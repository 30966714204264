import angular from 'angular';
import { Intl } from 'Roblox';
import intlUtilsModule from '../intlUtilsModule';

function languageResourceProvider() {
  const pageResources = {};
  const translationResources = {};
  let defaultNamespace;
  const intl = new Intl();
  let isTranslationResourceInitialized = false;

  // this will be deleted after rollout
  const getFromLangObject = (resourceKey, params) => {
    let translatedText = pageResources[resourceKey];
    if (!translatedText) {
      // eslint-disable-next-line no-console
      console.warn(
        `Language key '${resourceKey}' not found. Please check for any typo or a missing key.`
      );
      translatedText = '';
    } else if (params && Object.keys(params).length > 0) {
      translatedText = intl.f(translatedText, params);
    }

    return translatedText;
  };

  const getFromTranslationResources = (resourceKey, params, namespace) => {
    // if namespace is set get from namespace
    if (namespace && typeof namespace === 'string') {
      if (translationResources[namespace]) {
        return translationResources[namespace].get(resourceKey, params);
      }
      throw new Error(`Provided Namespace '${namespace}' is not found or is not set`);
    }

    // if namespace is not provided, get from default resource
    return translationResources[defaultNamespace].get(resourceKey, params);
  };

  this.setLanguageKeysFromFile = keys => {
    if (keys && typeof keys === 'object' && !Array.isArray(keys)) {
      angular.extend(pageResources, keys);
    }
  };

  this.setTranslationResources = resources => {
    angular.forEach(resources, resource => {
      translationResources[resource.namespace] = resource;
      isTranslationResourceInitialized = true;

      if (!defaultNamespace) {
        defaultNamespace = resource.namespace;
      }
    });
  };

  this.$get = [
    function languageResource() {
      return {
        get: isTranslationResourceInitialized ? getFromTranslationResources : getFromLangObject,
        intl
      };
    }
  ];
}

intlUtilsModule.provider('languageResource', languageResourceProvider);

export default languageResourceProvider;
